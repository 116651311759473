import myVisitors from "@src/api/contacts/myVisitors";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import { formatDate } from "@src/utils/formatDate";
import { ProfileVisitor } from "@src/utils/user";
import { useTranslation } from "react-i18next";

type Props = {
  searchQuery?: string;
};

const ContactMyVisitorsContainer = ({ searchQuery }: Props) => {
  const { t } = useTranslation();
  const { result: myVisitorsResult } = useInfiniteQueryOnScroll({
    queryKey: ["contacts", "my-followers", searchQuery],
    queryFn: async ({ pageParam }) => myVisitors({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  const VisitorComponent = ({ key, visitor }: { key: string; visitor: ProfileVisitor }) => {
    const placeholderImage = `/images/placeholder/${visitor.user_type}.png`;
    return (
      <li className="mb-4 flex items-center" key={key}>
        <img
          src={visitor.avatar_link || placeholderImage}
          alt={visitor.name}
          className="bottom-1 mr-4 size-12 rounded-full border"
        />
        <div>
          <p className="m-0 font-bold">{visitor.name}</p>
          <p className="text-sm">
            {t("main.contacts.statistics.visitedOn")}:{" "}
            {formatDate(new Date(visitor.visit_date).toISOString(), true)}
          </p>
        </div>
      </li>
    );
  };

  return (
    <>
      <ul className="list-none p-0">
        {myVisitorsResult.data?.pages.map(page =>
          page.data.map((item: ProfileVisitor) => (
            <VisitorComponent key={item.visitor_id} visitor={item} />
          )),
        )}
      </ul>
    </>
  );
};

export default ContactMyVisitorsContainer;
