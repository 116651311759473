import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import tw, { TwStyle } from "twin.macro";

type Props = {
  fontStyle?: TwStyle;
};

const Footer = ({ fontStyle }: Props) => {
  const { t } = useTranslation();
  const footerMenu = [
    {
      category: "User",
      key: "user",
      links: [
        {
          href: "info",
          hovertext: t("components.hovertext.info"),
          title: t("components.hovertext.info"),
          key: "info",
        },
        {
          href: "newsletter",
          hovertext: t("components.hovertext.newsletter"),
          key: "newsletter",
          title: t("components.hovertext.newsletter"),
        },
        {
          href: "help",
          key: "help",
          hovertext: t("components.hovertext.helparea"),
          title: t("components.hovertext.helparea"),
        },
        {
          key: "marketing",
          href: "marketing",
          hovertext: t("components.hovertext.marketing"),
          title: t("components.hovertext.marketing"),
        },
      ],
    },
    {
      category: "Website",
      key: "website",
      links: [
        {
          hovertext: t("main.signIn.imprint"),
          title: t("main.signIn.imprint"),
          key: "imprint",
          href: "impressum",
        },
        {
          hovertext: t("main.signIn.dataProtection"),
          title: t("main.signIn.dataProtection"),
          href: "datenschutz",
          key: "dataProtection",
        },
        {
          hovertext: t("main.profileViewTabs.editProfile.form.moreInformation.termsCondition"),
          href: "agb",
          title: t("main.profileViewTabs.editProfile.form.moreInformation.termsCondition"),
          key: "termsCondition",
        },
      ],
    },
    {
      category: "Marketing",
      key: "marketing",
      links: [
        {
          href: "partner",
          title: t("components.hovertext.partner"),
          key: "business",
          hovertext: t("components.hovertext.partner"),
        },
        {
          href: "glossar",
          title: t("components.hovertext.glossary"),
          hovertext: t("components.hovertext.glossary"),
          key: "glossary",
        },
      ],
    },
    {
      category: "App",
      key: "app",
      links: [
        {
          hovertext: t("components.hovertext.more"),
          href: "mehr",
          title: t("components.hovertext.more"),
          key: "more",
        },
      ],
    },
  ];

  return (
    <div className="text-sm text-gray-900">
      <div className="mb-2 flex flex-wrap justify-center gap-y-2 overflow-hidden">
        {footerMenu.map(footerLinks => {
          return (
            <div key={footerLinks.key}>
              {footerLinks.links.map(links => {
                return (
                  <NavLink
                    to={links.href}
                    key={links.key}
                    title={links.hovertext}
                    css={[tw`font-medium hover:bg-gray-200 px-1`, fontStyle]}
                  >
                    {links.title}
                  </NavLink>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="relative mx-auto flex h-[30px] w-[150px] flex-row">
        <img src="/logo.gif" alt="TRADEFOOX" />
        <div className="absolute -right-4 text-mini text-darkblue">© 2022</div>
      </div>
    </div>
  );
};

export default Footer;
